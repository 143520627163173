body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.git-icon {
  text-align: center;
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
  margin-top: 10px;
}

footer {
  text-align: center;
  position: fixed;
  height: 70px;
  margin-top: 0px;
  color: white;
  background-color: #800060;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.text-center {
  text-align: center;
  font-size: 20px;
}

.copyright-icon {
  font-size: 12px;
  margin-bottom: 10px;
}

#simple-button, #complex-button {
  background-color: white;
  color: black;
  border: 100px;
  width: 160px;
  height: 40px;
  margin: 10px;
  outline:none;
  border-radius: 28px;
  border: 2px solid #c495d5;
}

#copy-button {
  background-color: #800060;
  border-radius: 28px;
  border: 1px solid #800060;
  border: 100px;
  width: 160px;
  height: 40px;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  padding: 6px 31px;
  margin-top: 0;
  text-decoration: none;
  text-shadow: 0px 1px 0px #800060;
  outline: none;
}

#copy-button:hover {
  background-color: #8a25b1;
}

#simple-button:active, #complex-button:active, #copy-button:active {
  position:relative;
  top:1px;
}

.password-buttons, .copy {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
