.pass-box {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: #800060;
  margin: auto;
  width: 320px;
  height: 35px;
  border: 3px solid green;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #c495d5;
  background-color: #c495d526;
}

@media only screen and (max-width: 350px) {
  .pass-box {
    width: 260px;
    font-size: 20px;
    height: 25px;
  }
}

@media only screen and (max-width: 320px) {
  .text-heading {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .pass-box {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 200px;
  }
}

